<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                       <div class="col l-6 m-6 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="projectName" @md-selected="getProjectSelected" :md-options="projects" @md-changed="getProjects"  @md-opened="getProjects" :class="{'md-invalid': submitted && $v.entity.projectId.$error }">
                                            <label>Dự án</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.projectName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.projectId.isSelected">Vui lòng chọn dự án</span>
                                        </md-autocomplete>
                                        <md-button @click="openProject()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts" :class="{'md-invalid': submitted && $v.entity.contractId.$error }">
                                            <label>Hợp đồng</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.contractName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.contractId.isSelected">Vui lòng chọn hợp đồng</span>
                                        </md-autocomplete>
                                        <md-button @click="openContract()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                            </div>

                            <md-field :class="{'md-invalid': submitted && $v.entity.title.$error }">
                               <label for="title">Tiêu đề</label>
                               <md-input name="title" v-model="entity.title"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.title.required">Vui lòng nhập tiêu đề</span>
                           </md-field>
                       </div>
                        <div class="col l-6 m-6 c-12">
                            <md-datepicker v-model="entity.createDate" :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.createDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.createDate.required">Vui lòng chọn ngày tạo</span>
                            </md-datepicker>
                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="tool-bar">
                                <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                            </div>
                            <table class="data-table-2"> 
                                <thead> 
                                    <tr> 
                                        <th style="width:100px;">#</th> 
                                        <th style="width:35%;">Hàng hóa-vật tư</th> 
                                        <th style="width:25%;">Tên hàng</th> 
                                        <th style="width:20%;">Số lượng</th> 
                                        <th style="width:15%;">Hành động</th> 
                                    </tr> 
                                </thead> 
                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false"> 
                                    <tr v-for="(item, index) in entity.pklGoodsList" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <div class="grid-control">
                                                <input v-model="item.goods.goodsCode" class="form-control" type="text">
                                                <md-button @click="openGoods(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm hàng hóa - vật tư</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="center">
                                            <div class="group-control">
                                                {{item.goods.goodsName}}
                                            </div>
                                        </td>
                                        <td class="center">
                                            <div class="group-control">
                                                <input v-model="item.quantity" class="form-control" type="text">
                                            </div>
                                        </td>
                                        <td class="grid-action">
                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </td> 
                                    </tr> 
                                </tbody> 
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <contractList ref="contractList" @close="closeContract"/>
        <projectList ref="projectList" @close="closeProject"/>
        <goodsList ref="goodsList" @close="closeGoods"/>
   </div>
</template>
<script>
    import packingListService from '../../../api/packingListService';
    import contractService from '../../../api/contractService';
    import projectService from '../../../api/projectService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import contractList from '../../../components/popup/_ContractList.vue';
    import projectList from '../../../components/popup/_ProjectList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';

    export default ({
        components: {
            contractList,
            projectList,
            goodsList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật packing list'
        },
        data() {
            return {
                title: '',
                id: 0,
                loadding: false,
                submitted: false,
                entity: { id: 0, pklGoodsList: [], projectId: '', contractId: '', title: '', isActive: true, createDate: common.dateNow },
                contractName: '',
                contracts: [],
                projectName: '',
                projects: [],
                pklGoods: { id: 'id_' + common.getFakeId(), goodsId: 0, goods: { goodsCode: '', goodsName: '' }, quantity: 1, status: 1 }
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật packing list';
               this.getById();
            }
            else{
               this.title = 'Thêm mới packing list';
               this.entity.pklGoodsList.push(this.pklGoods);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //PKL Goods
            closeGoods(item){
                const selected = this.entity.pklGoodsList.findIndex(x => x.id == this.selectedId);
                this.entity.pklGoodsList[selected].goodsId = item.id;
                this.entity.pklGoodsList[selected].goods.goodsCode = item.goodsCode;
                this.entity.pklGoodsList[selected].goods.goodsName = item.goodsName;
                this.$refs.goodsList.close();
                this.selectedId = '';
            },

            openGoods(id){
                this.selectedId = id;
                this.$refs.goodsList.open();
            },

            delRow(item){
                const index = this.entity.pklGoodsList.findIndex(x => x.id == item.id);
                this.entity.pklGoodsList.splice(index, 1);
            },

            addRow(){
                this.entity.pklGoodsList.push(this.pklGoods);
            },

            //Contract
            closeContract(item){
                this.contractName = item.contractName;
                this.entity.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.entity.contractId = val.id;
                this.contractName = val.contractName;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Project
            closeProject(item){
                this.projectName = item.projectName;
                this.entity.projectId = item.id;
                this.$refs.projectList.close();
            },

            getProjectSelected(val){
                this.entity.projectId = val.id;
                this.projectName = val.projectName;
            },

            openProject(){
                this.$refs.projectList.open();
            },

            getProjects(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                projectService.getProjects(search).then((response) => {
                    if(response.statusCode == 200){
                        this.projects = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.pklGoodsList.length > 0){
                    this.entity.pklGoodsList = this.entity.pklGoodsList.filter(item => !(item.goodsId == 0));
                    this.entity.pklGoodsList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                else{
                    messageBox.showWarning("Vui lòng thêm hàng hóa");
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                packingListService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                packingListService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/packing-list');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                packingListService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.projectName = response.data.project.projectName;
                        this.contractName = response.data.contract.contractName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/packing-list');
            }
        },
        watch: { 
            projectName: function (val) { 
                if(val == ''){
                    this.entity.projectId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.entity.contractId = 0;
                }
            },
        },
        validations: {
            entity: {
                projectId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                contractId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                title: { required },
                createDate: { required },
            }
       }
     })

</script>
